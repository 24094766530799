crminfo = require('./crminfo.production').default
crminfo.isNerdify = true
crminfo.showOfferSubscription = true;

defaultConfig = require('./default-config')({
  domain: 'connect.dsh-agency.com',
  protocol: 'https',
  sentry: true,
  updateNotification: true,
  crmUrl: 'https://a4.dsh-agency.com/api/v1/crminfo',
  crmUrlA5: 'https://api.dsh-agency.com/api/v3'
  GMTOffset: '+0300',
  crmPluginEnabled: true,
  oauthEnabled: true
  # hotjarId: 770055
  # hotjarSv: 6
  authApiHost: 'https://oauth.dsh-agency.com'
  authBackApiHost: 'https://auth-back.dsh-agency.com'
  fileServerUrl: 'https://nwzc6p9uv6.execute-api.eu-west-1.amazonaws.com/production_v1'
  mqttUrl: 'wss://connect.dsh-agency.com:8084/mqtt'
  crminfo: crminfo
  dashboardTags: true
  isProductionMode: true
  xmpp: true
  showRankingRules: true
  isMyNerdify: true
  showTotalActiveConversationsSales: true
  showShiftAgents: true
})

module.exports = _.extend defaultConfig, {}
