export default {
  // host for API server
  apiHost: 'https://a4.dsh-agency.com',
  apiHostTickets: 'https://tickettool.dsh-agency.com',

  // version of used API
  apiVersionPartV1: '/api/v1',
  apiVersionPart: '/api/v3',
  apiVersionPartV4: '/api/v4',

  writerToolSite: 'https://myaccount.lrwriters.com',
  graderToolSite: 'https://gradertool.lrwriters.com',

  apiHostAuthBack: 'https://auth-back.dsh-agency.com',

  a4Host: 'https://a4.dsh-agency.com',
  a5Host: 'https://a5.dsh-agency.com',

  mynerdifyLink: 'http://mynerdify.com',
  geeklyLabSite: 'https://geeklylab.com',
  geeklyHubSite: 'https://geeklyhub.com',
  homeworkLabSite: 'https://homework-lab.com/',
  clientChatMessages: 'https://xmpp.nt.dsh-agency.com/nodes/get_items',
  clientChatFiles: 'https://xmpp.nt.dsh-agency.com/nodes/get_files',
  removeClientChatFiles: 'https://xmpp.nt.dsh-agency.com/nodes/remove_files',
  restoreClientChatFiles: 'https://xmpp.nt.dsh-agency.com/nodes/restore_files',


  fileServerUrl: 'https://nwzc6p9uv6.execute-api.eu-west-1.amazonaws.com/production_v1',

  apiHostSocket: 'wss://mqtt.dsh-agency.com:8084/mqtt',

  apiHostSmsTool: 'https://connect.dsh-agency.com',
  baseUrlWFH: 'https://connect.dsh-agency.com',
  apiVersionSmsTool: '/api',

  OCRProxy: 'https://api.dsh-agency.com/h2c',
  OCRResults: 'https://api.dsh-agency.com/ocrspace/parse/image',
  
  isSmsTool: true,
};
